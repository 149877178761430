<template>
    <div>
        <div class="comapnyPost">
            <div class="employSearch">
                <el-input v-model="search_key_word" placeholder="请输入单位、岗位或专业名称 进行搜索"></el-input>
                <el-button type="primary" @click="getSearchList">搜索</el-button>
            </div>
            <div class="employerList">
                <el-table :data="employerList">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="detail-box">

                                <div class="detail-box-cross-4">
                                    <div class="detail-box-item">
                                        <div class="detail-box-item-label">
                                            年龄要求：
                                        </div>
                                        <div class="detail- `box-item-value">
                                            {{ props.row.ageRequirements }}
                                        </div>
                                    </div>
                                    <div class="detail-box-item">
                                        <div class="detail-box-item-label">
                                            学历要求：
                                        </div>
                                        <div class="detail- `box-item-value">
                                            {{ props.row.educationalRequirements }}
                                        </div>
                                    </div>
                                    <div class="detail-box-item">
                                        <div class="detail-box-item-label">
                                            学位要求：
                                        </div>
                                        <div class="detail- `box-item-value">
                                            {{ props.row.academicDegree }}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="detail-box-cross-2"> -->
                                <div class="detail-box-item">
                                    <div class="detail-box-item-label">
                                        专业名称：
                                    </div>
                                    <div class="detail- `box-item-value">
                                        {{ props.row.professionalName }}
                                    </div>
                                </div>
                                <div class="detail-box-item">
                                    <div class="detail-box-item-label">
                                        研究生专业三级目录限制要求：
                                    </div>
                                    <div class="detail- `box-item-value">
                                        {{ props.row.professionalRequirements }}
                                    </div>
                                </div>
                                <!-- </div> -->
                                <div class="detail-box-item">
                                    <div class="detail-box-item-label">
                                        岗位描述：
                                    </div>
                                    <div class="detail- `box-item-value">
                                        {{ props.row.jobDescription }}
                                    </div>
                                </div>
                                <div class="detail-box-item">
                                    <div class="detail-box-item-label">
                                        备注：
                                    </div>
                                    <div class="detail-box-item-value">
                                        {{ props.row.remark }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="岗位代码" min-width="70" align="center" prop="nameOfSuperiorUnit">
                    </el-table-column>
                    <el-table-column label="单位名称" min-width="100" align="center" prop="nameOfEmployer">
                    </el-table-column>
                    <el-table-column label="专业名称" min-width="400" align="center" prop="professionalName">
                    </el-table-column>
                    <el-table-column label="岗位类别" min-width="80" align="center" prop="jobCategory">
                    </el-table-column>
                    <el-table-column label="招聘人数" min-width="60"  align="center" prop="recruitingNum">
                    </el-table-column>
                    <el-table-column fixed="right" min-width="80" align="center" label="操作" >
                        <template slot-scope="scope">
                            <el-button @click="deliver(scope.row)" type="text" size="small">立即投递</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <applyJobDialog ref="apply_dialog_ref"></applyJobDialog>
    </div>
</template>
<script>
import { getTalentIntroductionPositions } from "@/api/index.js";
import applyJobDialog from './dialog_apply_job.vue'
export default {
    components:{
        applyJobDialog,
    },
    props: {
        talentIntroductionId: { type: String, default: '' },
    },
    data() {
        return {
            search_key_word: '', //岗位搜索关键词
            employerList: []

        }
    },
    mounted() {
        this.getSearchList();

    },
    methods: {
        //搜索查询列表
        getSearchList() {
            getTalentIntroductionPositions({
                searchValue: this.search_key_word,
                talentIntroductionId: this.talentIntroductionId,
            }).then(res => {
                this.employerList = res.rows
            })
        },

        //申请提交弹框
        deliver(item){
            let copy_data = JSON.parse(JSON.stringify(item))
            this.$refs.apply_dialog_ref.openInit(copy_data)

        },


    }

}

</script>
<style lang="scss" scoped>
.comapnyPost {
    width: 1200px;
    margin: 20px auto;
    overflow: hidden;

    .employSearch {
        margin-bottom: 20px;
        margin-left: 200px;

        .el-input {
            width: 600px;
        }

        .el-input__inner {
            width: 600px;
            height: 45px;
            line-height: 45px;
            font-size: 14px;
            border: 1px solid #0071fb;
            border-radius: 5px;
            color: #555;
        }

        .el-button--primary {
            background-color: #0071fb;
            color: #FFF;
            height: 45px;
            width: 120px;
            text-align: center;
            font-size: 15px;
            letter-spacing: 2px;
            border: none;
            border-radius: 5px;
            box-shadow: #DDD 0px 0px 5px;
            cursor: pointer;
            margin-left: 25px;
            line-height: 24px;
        }

    }



    .employerList {
        // width: 880px;

        .expandEmploy {
            clear: both;
            margin-bottom: 15px;
            overflow: hidden;

            span {
                font-weight: 600;
                display: block;
                float: left;
                width: 110px;
                text-align: right;
                line-height: 30px;
            }

            label {
                display: block;
                float: left;
                white-space: pre-wrap;
                line-height: 30px;
                letter-spacing: 1px;
                width: 700px;
            }
        }
    }

    .detail-box {
        // border-bottom: 2px solid #ccc;

        .detail-box-cross-2 {
            display: flex;
            justify-content: space-between;

            .detail-box-item {
                display: inline-flex;
                width: calc((100% / 2) - 15px);
            }
        }

        .detail-box-cross-3 {
            display: flex;
            justify-content: space-between;

            .detail-box-item {
                display: inline-flex;
                width: calc((100% / 3) - 15px);
            }
        }

        .detail-box-cross-4 {
            display: flex;
            justify-content: space-between;

            .detail-box-item {
                display: inline-flex;
                width: calc((100% / 4) - 15px);
            }
        }

        .detail-box-item {
            display: flex;
            margin: 10px 0;
            height: auto;
            overflow: hidden;

            .detail-box-item-label {
                width: auto;
                min-width: 80px;
                display: inline-block;
                color: #959595;
                height: auto;
                overflow: hidden;
                // background: red;
            }

            .detail-box-item-value {
                width: auto;
                max-width: calc(100% - 90px);
                display: inline-block;
                height: auto;
                overflow: hidden;

            }
        }

    }
}

:deep(.el-table__expanded-cell) {
    // background: #f4f4f4 !important;
    padding: 12px 30px 12px 70px !important;
    border-bottom: 2px solid #ccc!important;
}
</style>