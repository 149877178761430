<template>
  <div id="joinUs">
    <div class="banner">
    </div>
    <div class="nav">
      <ul>
        <li @click="changeTabFun(1)" :class="{ active: sub == 1 }">报名流程</li>
        <li @click="changeTabFun(2)" :class="{ active: sub == 2 }">招聘公告</li>
        <li @click="changeTabFun(3)" :class="{ active: sub == 3 }">报名通道</li>
        <li @click="changeTabFun(4)" :class="{ active: sub == 4 }">报名记录</li>
      </ul>
    </div>
    <div class="flow" v-show="sub == 1">
      <div class="flowCt">
        <div class="flow-item">
          <img src="@/assets/imgs/application/user.png" />
          <span>注册账号</span>
          <label>根据个人手机号码成功注册账户</label>
        </div>
        <img class="arrow" src="@/assets/imgs/application/rightIcon.png" />
        <div class="flow-item">
          <img src="@/assets/imgs/application/position.png" />
          <span>选择岗位</span>
          <label>查看岗位详情</label>
          <label>选择意向岗位</label>
        </div>
        <img class="arrow" src="@/assets/imgs/application/rightIcon.png" />
        <div class="flow-item">
          <img src="@/assets/imgs/application/message.png" />
          <span>立即投递</span>
          <label>选中意向岗位点击岗位对应</label>
          <label><em>&lt;立即投递&gt;</em>按钮</label>
        </div>
        <p class="arrowBottom">
          <img src="@/assets/imgs/application/bottomIcon.png" />
        </p>
        <div class="flow-item">
          <img src="@/assets/imgs/application/table.png" />
          <span>提交报名表</span>
          <label>等待资格审核</label>
        </div>
        <img class="arrow" src="@/assets/imgs/application/leftIcon.png" />
        <div class="flow-item">
          <img src="@/assets/imgs/application/upload.png" />
          <span>上传照片</span>
          <label>点击<em>&lt;上传&gt;</em>按钮上传指定要求照片</label>
        </div>
        <img class="arrow" src="@/assets/imgs/application/leftIcon.png" />
        <div class="flow-item">
          <img src="@/assets/imgs/application/edit.png" />
          <span>填写报名表</span>
          <label>按照报名表要求填写报名</label>
          <label>表中信息</label>
        </div>
      </div>
      <div class="flow-tips">
        报名期间注意请随时电脑登录报名系统，首页右上角&lt;查询报名状态&gt;，查询材料审核状态
      </div>
    </div>
    <div class="notice" v-show="sub == 2">
      <h3>{{ noticeInfo.title }}</h3>
      <div class="notice-ct" v-html="noticeInfo.consultationInfo"></div>
      <br /><br />
      <span>
        <h4>公告信息：</h4>
      </span>
      <br />
      <div class="notice-ct" v-html="noticeInfo.contents"></div>
    </div>
    <div class="thorough" v-show="sub == 3">
      <!-- <div class="selectTh" v-show="!accessSub">
        <p>
          <img src="@/assets/imgs/application/qrcode.png" />
        </p>
      </div> -->
      <div class="comapnyPost" >
        <jobList :talentIntroductionId="talentIntroductionInfoId"></jobList>
      </div>

    </div>
    <div class="record" v-show="sub == 4">
          <el-form ref="form" :model="recordInfo" label-width="80px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="报名单位" prop="nameOfEmployer">
                <el-input v-model="recordInfo.nameOfEmployer" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="岗位类别" prop="jobCategory">
                <el-input v-model="recordInfo.jobCategory" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业名称" prop="professionalName">
                <el-input v-model="recordInfo.professionalName" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="recordInfo.phone" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="审核状态" prop="phone">
                <el-input v-model="recordInfo.statusName" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="审核时间" prop="reviewedTime">
                <el-input v-model="recordInfo.reviewedTime" placeholder="请输入" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="审核意见" prop="reviewComments" v-if="recordInfo.status === 0">
            <el-input v-model="recordInfo.reviewComments" :row="3" type="textarea"  placeholder="请输入" :disabled="true"/>
          </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getRegistrationList, getRecruitmentAnnouncement, levelRegistration, getDictData, getTalentIntroductionEnterprises } from "@/api/index.js";
import jobList from './component/job_list.vue';
export default {
  name: "joinUs",
  components: {
    jobList,
  },
  data() {
    return {
      info: {},
      sub: 1,
      noticeInfo: {},
      enterpriseList: [],
      employerList: [],
      talentIntroductionInfoId: '',
      recruitmentPositions: '',
      showDeliver: false,
      deliverForm: {
        education: '',
        idCard: '',
        mailbox: '',
        phone: '',
        talentIntroductionInfoId: '',
        userName: '',
        headSculpture: ''
      },
      backgroundTypeList: [],
      baseUrl: '',
      userInfo: {},
      recordInfo: {},
      ruleForm: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
        ],
        education: [
          { required: true, message: '请选择最高学历', trigger: 'change' }
        ],
        headSculpture: [
          { required: true }
        ],
      }
    }
  },
  created(){
    let get_query = this.$route.query;
    this.talentIntroductionInfoId = get_query.id || ''
  },
  mounted() {
    this.getRecruitmentAnnouncement()
    this.getTalentIntroductionEnterprises()
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    getRegistrationList() {
      getRegistrationList().then(res => {
        if(res.data.status === 0){
          res.data.statusName = '审核不通过'
        }else if(res.data.status === 1){
          res.data.statusName = '审核中'
        }else if(res.data.status === 2){
          res.data.statusName = '审核通过'
        }
        this.recordInfo = res.data
      })
    },
    avatarSuccess(res) {
      this.deliverForm.headSculpture = res.url
    },
    levelRegistration() {
      levelRegistration(this.deliverForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '报名成功',
            type: 'success'
          })
          this.showDeliver = false
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'background_type_two') {
          this.backgroundTypeList = res.rows
        }
      })
    },
    changeTabFun(index) {
      this.sub = index
      this.getRegistrationList();
    },
    getRecruitmentAnnouncement() {
      getRecruitmentAnnouncement({
        id: this.$route.query.id
      }).then(res => {
        this.noticeInfo = res.data
      })
    },
    //获取招聘企业列表
    getTalentIntroductionEnterprises() {
      getTalentIntroductionEnterprises({
        /*type: this.accessSub*/
        id: this.$route.query.id,
        type: 0
      }).then(res => {
        this.enterpriseList = res.rows
      })
    },
    
  }
};
</script>

<style lang="scss">
#joinUs {
  width: 100%;
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;

  .banner {
    height: 460px;
    width: 100%;
    background: url('../../assets/imgs/company/joinUs.png');
    background-size: cover;
  }

  .nav {
    background: linear-gradient(to right, #407cd4 5%, #339DFF, #407cd4);
    height: 80px;
    line-height: 80px;

    ul {
      width: 1200px;
      margin: 0 auto;

      li {
        float: left;
        font-size: 23px;
        width: 240px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }

      li:hover {
        background: #0071fb;
        transition: all .2s linear;
      }
    }

    .active {
      background: #0071fb;
    }
  }

  .flowCt {
    width: 1200px;
    height: 523px;
    background: #F6FAFF;
    border-radius: 2px;
    margin: 20px auto;

    .flow-item {
      width: 330px;
      height: 140px;
      padding-top: 47px;
      text-align: center;
      font-size: 18px;
      color: #222222;
      float: left;
      line-height: 26px;

      span {
        display: block;
        margin-bottom: 5px;
      }

      label {
        color: #666;
        display: block;

        em {
          color: #0071fb;
          font-style: normal;
        }

      }
    }

    .arrow {
      float: left;
      width: 20px;
      height: 35px;
      margin: 100px 30px;
    }

    .arrowBottom {
      clear: both;
      text-align: right;
      margin-right: 180px;

      img {
        width: 35px;
        height: 20px;
      }
    }
  }

  .flow-tips {
    width: 1200px;
    height: 100px;
    background: #D0E6FF;
    border-radius: 4px;
    margin: 50px auto;
    line-height: 100px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 1px;
    color: #0071fb;
  }

  .notice {
    width: 1120px;
    min-height: 300px;
    background: #F6FAFF;
    border-radius: 2px;
    margin: 20px auto;
    padding: 40px;
    overflow: hidden;

    h3 {
      text-align: center;
      margin-bottom: 30px;
      font-size: 29px;
      color: #333;
    }

    p {
      font-size: 18px;
      color: #333;
      line-height: 40px;
      text-indent: 2em;

      strong {
        padding: 7px 10px;
        border-radius: 5px;
      }
    }
  }

  .thorough {
    .selectTh {
      width: 1200px;
      margin: 50px auto;
      overflow: hidden;

      p {
        width: 420px;
        float: left;
        height: 450px;
        background: #fff;
        margin-left: 33%;
        border-radius: 12px;
        text-align: center;
        cursor: pointer;

        img {
          width: 410px;
          margin-top: 30px;
        }

        span {
          display: block;
          font-size: 30px;
          color: #666;
          margin-top: 10px;
        }

        label {
          width: 201px;
          display: inline-block;
          text-align: center;
          margin-top: 25px;
          color: #fff;
          line-height: 46px;
          font-size: 18px;
          height: 46px;
          background: #0077FF;
          border-radius: 4px;
          cursor: pointer;
        }

        label:hover {
          background: #2b77e8
        }
      }
    }
  }

  .record {
    width: 1200px;
    margin: 20px auto;

    .headSculpture {
      width: 60px;
    }
  }
}
</style>

<style lang="scss">
.thorough {
  .el-menu {
    width: 300px;
  }

  .el-menu-item-group__title {
    padding: 0;
  }


}
</style>
