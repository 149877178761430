<template>
    <div>
        <el-dialog title="提交报名" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" :visible="dialogVisible" @close="afterCloseInitFun" width="1145px">
            <div class="main-cont">
                <!-- 基本信息 -->
                <div class="model-box" ref="base_area">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>基本信息</div>
                        </div>
                        <div class="model-title-btn">
                            <el-button
                                :icon="toggle_model.base_info_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('base_info_toggle')"></el-button>
                        </div>
                    </div>
                    <el-form ref="base_form" :model="form" :rules="rules" label-width="85px">
                        <div class="model-box-cont" v-if="toggle_model.base_info_toggle">

                            <div class="model-box-cont-item">
                                <el-form-item label="姓名:" prop="name">
                                    <el-input v-model="form.name" size="small"></el-input>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="手机号:" prop="phone">
                                    <el-input v-model="form.phone" size="small"></el-input>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="性别:" prop="sex">
                                    <el-select v-model="form.sex" size="small">
                                        <el-option v-for="(item, index) in sexOptions" :key="index" :label="item.label"
                                            :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="出生日期:" prop="birthday">
                                    <el-date-picker v-model="form.birthday" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" size="small" />
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item model-box-cont-item-out">
                                <div class="model-box-cont-item model-box-cont-item-4">
                                    <el-form-item label="身份证号:" prop="IdNum">
                                        <el-input v-model="form.IdNum" size="small"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="model-box-cont-item model-box-cont-item-4">
                                    <el-form-item label="籍贯:" prop="native_place">
                                        <el-cascader v-model="form.native_place" :options="provinceCityCountryData"
                                            separator='-' size="small"></el-cascader>
                                    </el-form-item>
                                </div>

                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="身份证正面照:" prop="ID_img_front">
                                    <div class="img-box">
                                        <upload-img ref="ID_img_front" filed="ID_img_front" isSingle
                                            :callFun="sendUpload"></upload-img>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="身份证反面照:" prop="ID_img_back">
                                    <div class="img-box">
                                        <upload-img ref="ID_img_back" filed="ID_img_back" isSingle
                                            :callFun="sendUpload"></upload-img>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="近期一寸免冠照片:" prop="near_photo">
                                    <div class="img-box">
                                        <upload-img ref="near_photo" filed="near_photo" isSingle
                                            :callFun="sendUpload"></upload-img>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item ">
                                <el-form-item label="民族:" prop="nation">
                                    <el-select v-model="form.nation" size="small">
                                        <el-option v-for="(item, index) in nationOptions" :key="index"
                                            :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="model-box-cont-item">
                                <el-form-item label="政治面貌:" prop="politice_status">
                                    <el-select v-model="form.politice_status" size="small">
                                        <el-option v-for="(item, index) in politiceOptions" :key="index"
                                            :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="生源地:" prop="college_place">
                                    <el-cascader v-model="form.college_place" :options="provinceCityCountryData"
                                            separator='-' size="small"></el-cascader>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="学历:" prop="educational_level">
                                    <el-select v-model="form.educational_level" size="small">
                                        <el-option v-for="(item, index) in educationaleOptions" :key="index"
                                            :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item">
                                <el-form-item label="省市区:" prop="region">
                                    <el-cascader v-model="form.region" :options="provinceCityCountryData"
                                            separator='-' size="small"></el-cascader>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item model-box-cont-item-2">
                                <el-form-item label="联系地址:" prop="address">
                                    <el-input v-model="form.address" size="small"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>
                <!-- 高中 -->
                <div class="model-box " ref="child_high_area" v-if="form.qualification_high_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>高中</div>
                            <div class="notice-box">
                                (填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('qualification_high')">添加明细</el-button>
                            <el-button
                                :icon="toggle_model.education_high_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('education_high_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.education_high_toggle">
                        <educationCard ref="qualification_high_list"
                            v-for="(item, index) in form.qualification_high_list" :key="index" :form="item"
                            :index="index + 1" filed="qualification_high_list" :degree_cert="false" :chsi_cert="false"
                            @removeCall="delChildItemFun">
                        </educationCard>
                    </div>
                </div>
                <!-- 大专 -->
                <div class="model-box " ref="child_college_area" v-if="form.qualification_college_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>大专</div>
                            <div class="notice-box">
                                (填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('qualification_college')">添加明细</el-button>
                            <el-button
                                :icon="toggle_model.education_college_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('education_college_toggle')"></el-button>
                        </div>

                    </div>
                    <div class="model-box-cont" v-if="toggle_model.education_college_toggle">
                        <educationCard ref="qualification_college_list"
                            v-for="(item, index) in form.qualification_college_list" :key="index" :form="item"
                            :index="index + 1" filed="qualification_college_list" :degree_cert="false"
                            @removeCall="delChildItemFun">
                        </educationCard>
                    </div>
                </div>
                <!-- 本科 -->
                <div class="model-box " ref="child_bs_area" v-if="form.qualification_bs_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>本科</div>
                            <div class="notice-box">
                                (填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('qualification_bs')">添加明细</el-button>
                            <el-button
                                :icon="toggle_model.education_bs_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('education_bs_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.education_bs_toggle">
                        <educationCard ref="qualification_bs_list" v-for="(item, index) in form.qualification_bs_list"
                            :key="index" :form="item" :index="index + 1" filed="qualification_bs_list"
                            @removeCall="delChildItemFun">
                        </educationCard>
                    </div>
                </div>
                <!-- 研究生 -->
                <div class="model-box " ref="child_grad_area" v-if="form.qualification_grad_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>研究生</div>
                            <div class="notice-box">
                                (填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <el-button type="primary" size="mini" plain
                            @click="addChildItemFun('qualification_grad')">添加明细</el-button>
                        <el-button
                            :icon="toggle_model.education_grad_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                            size="mini" circle @click="toggleModelFun('education_grad_toggle')"></el-button>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.education_grad_toggle">
                        <educationCard ref="qualification_grad_list"
                            v-for="(item, index) in form.qualification_grad_list" :key="index" :form="item"
                            :index="index + 1" filed="qualification_grad_list" @removeCall="delChildItemFun">
                        </educationCard>
                    </div>
                </div>
                <!-- 博士 -->
                <div class="model-box " ref="child_scholar_area" v-if="form.qualification_scholar_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>博士</div>
                            <div class="notice-box">
                                (填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('qualification_scholar')">添加明细</el-button>
                            <el-button
                                :icon="toggle_model.education_scholar_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('education_scholar_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.education_scholar_toggle">
                        <educationCard ref="qualification_scholar_list"
                            v-for="(item, index) in form.qualification_scholar_list" :key="index" :form="item"
                            :index="index + 1" filed="qualification_scholar_list" @removeCall="delChildItemFun">
                        </educationCard>
                    </div>
                </div>
                <!-- 岗位信息详情 -->
                <div class="model-box">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>岗位信息</div>
                        </div>
                        <div class="model-title-btn">
                            <el-button
                                :icon="toggle_model.job_detail_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('job_detail_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.job_detail_toggle">
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">岗位代码:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.region }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">所在区域:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.regionName }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">单位名称:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.nameOfEmployer }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">岗位类别:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.jobCategory }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">招聘人数:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.recruitingNum }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">年龄:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.ageRequirements }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">学历:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.educationalRequirements }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-derail">
                            <div class="model-box-cont-item-label">学位:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.academicDegree }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-item-2 model-box-cont-derail">
                            <div class="model-box-cont-item-label">专业名称:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.professionalName }}</div>
                        </div>
                        <div class="model-box-cont-item model-box-cont-item-2 model-box-cont-derail">
                            <div class="model-box-cont-item-label">专业三级目录限制要求:</div>
                            <div class="model-box-cont-item-value">{{ job_detail.professionalRequirements }}</div>
                        </div>

                    </div>
                </div>
                <!-- 工作经历 -->
                <div class="model-box " ref="vitae_area" v-if="form.vitae_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>学习/工作经历</div>
                            <div class="notice-box">
                                (多段学习及工作经历，请按时间顺序填写，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('vitae_list')">添加明细</el-button>
                            <el-button :icon="toggle_model.vitae_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('vitae_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.vitae_toggle">
                        <workCard ref="vitae_list" v-for="(item, index) in form.vitae_list" :key="index" :form="item"
                            :index="index + 1" filed="vitae_list" @removeCall="delChildItemFun">
                        </workCard>
                    </div>
                </div>
                <!-- 主要家庭成员 -->
                <div class="model-box " ref="family_area" v-if="form.family_list.length > 0">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>主要家庭成员</div>
                            <div class="notice-box">
                                (多个家庭成员，可点击 “<span>添加明细</span>” 增加填写)
                            </div>
                        </div>
                        <div class="model-title-btn">
                            <el-button type="primary" size="mini" plain
                                @click="addChildItemFun('family_list')">添加明细</el-button>
                            <el-button :icon="toggle_model.family_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('family_toggle')"></el-button>
                        </div>
                    </div>
                    <div class="model-box-cont" v-if="toggle_model.family_toggle">
                        <familyCard ref="family_list" v-for="(item, index) in form.family_list" :key="index"
                            :form="item" :index="index + 1" filed="family_list" @removeCall="delChildItemFun">
                        </familyCard>
                    </div>
                </div>
                <!-- 其他信息 -->
                <div class="model-box">
                    <div class="model-title">
                        <div class="model-title-box">
                            <div>其他信息</div>
                        </div>
                        <div class="model-title-btn">
                            <el-button :icon="toggle_model.other_toggle ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                size="mini" circle @click="toggleModelFun('other_toggle')"></el-button>
                        </div>
                    </div>
                    <el-form ref="other_form" :model="form" :rules="rules" label-width="80px" label-position="top">
                        <div class="model-box-cont" v-if="toggle_model.other_toggle">
                            <div class="model-box-cont-item model-box-cont-item-4">
                                <el-form-item label="论文/课题/社会实践经历:" prop="thesis_report">
                                    <el-input v-model="form.thesis_report" type="textarea" size="small"
                                        rows="4"></el-input>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item model-box-cont-item-2">
                                <el-form-item label="爱好特长:" prop="hobby">
                                    <el-input v-model="form.hobby" type="textarea" size="small" rows="4"></el-input>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item model-box-cont-item-2">
                                <el-form-item label="自我评价:" prop="self_evaluation">
                                    <el-input v-model="form.self_evaluation" type="textarea" size="small"
                                        rows="4"></el-input>
                                </el-form-item>
                            </div>

                            <div class="model-box-cont-item model-box-cont-item-4">
                                <el-form-item label="获奖证书照片:">
                                    <div class="img-list">
                                        <div class="img-box" v-for="(item, index) in form.certificate_img_list"
                                            :key="index">
                                            <div class="img-box-btn"
                                                @click="removeUploadFun(index, 'certificate_img_list')">
                                                <i class="el-icon-delete"></i>
                                            </div>
                                            <el-image :src="item" fit="contain" />
                                        </div>
                                        <div class="img-box">
                                            <upload-img ref="certificate_img_list" filed="certificate_img_list"
                                                :callFun="sendUpload"></upload-img>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="model-box-cont-item model-box-cont-item-4">
                                <el-form-item label="其他材料照片:">
                                    <div class="img-list">
                                        <div class="img-box" v-for="(item, index) in form.other_img_list" :key="index">
                                            <div class="img-box-btn" @click="removeUploadFun(index, 'other_img_list')">
                                                <i class="el-icon-delete"></i>
                                            </div>
                                            <el-image :src="item" fit="contain" />
                                        </div>
                                        <div class="img-box">
                                            <upload-img ref="other_img_list" filed="other_img_list"
                                                :callFun="sendUpload"></upload-img>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="agree-box">
                    <el-checkbox v-model="aggrement_notice" :border="true">
                        <div class="agree-box-txt">
                            真实性声明：本人对以上填报信息的真实性、准确性、和完整性富则，凡因所填写信息即提交材料不真实、不准确、不完整而影响报名、资格审核、考试、录用的，由本人承担全部责任。
                        </div>
                    </el-checkbox>

                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFun" size="small">确 定</el-button>
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>

            </span>
        </el-dialog>

    </div>
</template>
<script>
import { getNationList, uploadImageFile, levelRegistration } from "@/api/index.js";
import { checkIDCardNum } from '@/utils';
import provinceCityCountryData from './province_city_county_data.json'
import uploadImg from './uploag_img.vue';
import educationCard from './education_card.vue'
import familyCard from './family_card.vue'
import workCard from './work_card.vue'
export default {
    name: "applyJobDialog",
    components: {
        uploadImg,
        educationCard,
        familyCard,
        workCard,
    },
    data() {
        //校验身份证号码规则
        var checkIDnum = (rule, value, callback) => {
            if (!!!value) {
                callback(new Error('请填写身份证号码'));
            } else if (!checkIDCardNum(value)) {
                callback(new Error('请填写正确的身份证号码'));
            } else {
                callback();
            }
        };
        return {
            provinceCityCountryData,
            dialogVisible: false,
            //真实声明是否确认
            truthfulStatement: false,
            //top顶部banner 通知，富文本框信息
            topBannerInfo: {},
            //民族选项列表
            nationOptions: [],
            //性别选项列表
            sexOptions: [
                { label: '男', value: '0' },
                { label: '女', value: '1' },
            ],
            //政治面貌选项列表
            politiceOptions: [
                { label: '党员', value: '1' },
                { label: '共青团员', value: '2' },
                { label: '群众', value: '3' },
                { label: '民主党派成员', value: '4' },
                { label: '无党派人士', value: '5' },
            ],
            //学历选项列表
            educationaleOptions: [
                { label: '高中', value: '高中' },
                { label: '大专', value: '大专' },
                { label: '本科', value: '本科' },
                { label: '研究生', value: '研究生' },
                { label: '博士', value: '博士' },

            ],
            //应聘岗位代码选项列表
            jobCodeOptions: [],
            //家庭成员选项列表
            familyRelationShipOptions: [
                { label: '父亲', value: '父亲' },
                { label: '母亲', value: '母亲' },
                { label: '哥哥', value: '哥哥' },
                { label: '姐姐', value: '姐姐' },
                { label: '弟弟', value: '弟弟' },
                { label: '妹妹', value: '妹妹' },
            ],
            //==================================================
            //单个学位信息
            education_item: {
                school_name: '',//毕业学校
                specialty: '',//专业
                main_course: '', //主修课程
                graduation_time: '',//获得学位时间
                graduation_img: '',//毕业证照片
                degree_img: '',//学位证照片
                chsi_img: '', //学信网信息截图
            },
            //单个学习、工作经历信息
            vitae_item: {
                start_date: '', //开始时间
                end_date: '',//结束时间
                organization_name: '',//单位名称
                job_name: '',//职务
            },
            //单个家庭成员信息
            family_item: {
                appellation: '',//称谓
                name: '',//姓名
                birthday: '',//出生年月
                work_place: '',//工作单位或学校
                job_name: '', //职务
            },
            //提交表单西信息
            form: {
                talentIntroductionInfoId: '',
                name: '',
                phone: '',//手机
                near_photo: '',//近期照片
                IdNum: '',//身份证号码
                ID_img_front: '', //身份证照片--正面
                ID_img_back: '', //身份证照片--反面
                sex: '',//性别
                nation: '', //民族
                birthday: '', //出生日期
                native_place: '',//籍贯
                college_place: '',//生源地
                politice_status: '',//政治面貌
                educational_level: '',//学历
                region: '', //省市区
                address: '',//详细联系地址
                qualification_high_list: [], //高中学历
                qualification_college_list: [], //专科学历
                qualification_bs_list: [], //本科学历
                qualification_grad_list: [], //研究省学历
                qualification_scholar_list: [], //博士学历
                promise: '', //声明
                vitae_list: [{
                    start_date: '', //开始时间
                    end_date: '',//结束时间
                    organization_name: '',//单位名称
                    job_name: '',//职务
                },], //学习、工作经历
                family_list: [{
                    appellation: '',//称谓
                    name: '',//姓名
                    birthday: '',//出生年月
                    work_place: '',//工作单位或学校
                    job_name: '', //职务
                },], //家庭成员
                hobby: '',//爱好
                thesis_report: '',//论文、课题、社会实践经历
                certificate_img_list: [], //获奖证书照片
                other_img_list: [], //其他材料照片
                self_evaluation: '',//自我评价
                apply_date: '', //登记日期
            },

            //控制显隐
            toggle_model: {
                base_info_toggle: true,//基本信息区
                education_high_toggle: true, //高中区
                education_college_toggle: true, //大专区
                education_bs_toggle: true, //本科区
                education_grad_toggle: true,  //研究生区
                education_scholar_toggle: true,  //博士区
                job_detail_toggle: true, //应聘岗位详情区
                vitae_toggle: true, //学习工作经历区
                family_toggle: true,//家庭成员区
                hobby_toggle: true,//爱好特长区
                other_toggle: true, //其他区
            },
            //应聘岗位详情
            job_detail: {},

            rules: {
                name: [
                    { required: true, message: '请输入姓名' },
                ],
                phone: [
                    { required: true, message: '请输入手机号' },
                ],
                sex: [
                    { required: true, message: '请选择性别' },
                ],
                IdNum: [
                    { required: true, message: '请填写身份证号码' },
                    { validator: checkIDnum },
                ],
                ID_img_front: [
                    { required: true, message: '请上传身份证正面照',trigger: 'change' },
                ],
                ID_img_back: [
                    { required: true, message: '请上传身份证反面照',trigger: 'change' },
                ],
                near_photo: [
                    { required: true, message: '请上传近期免冠彩色照片',trigger: 'change' },
                ],
                nation: [
                    { required: true, message: '请选择民族' },
                ],
                birthday: [
                    { required: false, message: '请选择出生日期' },
                ],
                native_place: [
                    { required: true, message: '请选择籍贯' },
                ],
                college_place: [
                    { required: true, message: '请选择生源地' },
                ],
                politice_status: [
                    { required: true, message: '请选择政治面貌' },
                ],
                educational_level: [
                    { required: true, message: '请选择学历' },
                ],
                region: [
                    { required: true, message: '请选择现居省市区' },
                ],
                address: [
                    { required: true, message: '请填写详细地址' },
                ],
                thesis_report: [
                    { required: false, message: '请填写论文、课题、社会实践经历' },
                ],
                hobby: [
                    { required: false, message: '请填写爱好' },
                ],
                self_evaluation: [
                    { required: false, message: '请填写自我评价' },
                ],
            },
            aggrement_notice: false,//是否同意真实性声明
        }
    },
    watch: {
        'form.educational_level': {
            handler(newVal, oldVal) {
                this.educationalChangeUnion(newVal)
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        // this.getNationOptions();

    },
    methods: {
        openInit(data = {}) {
            this.job_detail = { ...data }
            this.getNationOptions();
            this.dialogVisible = true;

        },
        //获取民族列表选项
        getNationOptions() {
            getNationList({}).then(res => {
                if (res.code == 200) {
                    let get_list = res.data.map(item => {
                        item.value = item.nationId;
                        item.label = item.nationName;
                        return item;
                    })
                    localStorage.setItem('nationOptions', get_list)
                    this.nationOptions = get_list;
                }
            })
        },

        //各模块控制显隐
        toggleModelFun(value) {
            let get_name = value || ''
            let toggle_model = this.toggle_model || false
            toggle_model[get_name] = !!!toggle_model[get_name]
        },

        //学历联动
        educationalChangeUnion(level) {
            if (!!!level) return false;
            let get_form_data = this.form;
            var data_item = JSON.parse(JSON.stringify(this.education_item))
            let except_list = ['高中', '大专'];
            if (except_list.indexOf(level) != -1) {
                get_form_data.qualification_bs_list = [];
                get_form_data.qualification_grad_list = [];
                get_form_data.qualification_scholar_list = [];
            } else {
                get_form_data.qualification_high_list = [];
                get_form_data.qualification_college_list = [];
            }
            switch (level) {
                case '高中':
                    var high_list = get_form_data.qualification_high_list || [];
                    if (high_list.length == 0) {
                        high_list.push(data_item);
                    }
                    get_form_data.qualification_college_list = [];
                    break;
                case '大专':
                    var college_list = get_form_data.qualification_college_list;
                    if (college_list.length == 0) {
                        college_list.push(data_item);
                    }
                    get_form_data.qualification_high_list = [];
                    break;
                case '本科':
                    var bs_list = get_form_data.qualification_bs_list;
                    if (bs_list.length == 0) {
                        bs_list.push(data_item);
                    }
                    get_form_data.qualification_grad_list = [];
                    get_form_data.qualification_scholar_list = [];
                    break;
                case '研究生':
                    var bs_list = get_form_data.qualification_bs_list;
                    if (bs_list.length == 0) {
                        let copy_data = JSON.parse(JSON.stringify(data_item))
                        bs_list.push(copy_data);
                    }
                    var grad_list = get_form_data.qualification_grad_list;
                    if (grad_list.length == 0) {
                        grad_list.push(data_item);
                    }
                    get_form_data.qualification_scholar_list = [];
                    break;
                case '博士':
                    var bs_list = get_form_data.qualification_bs_list;
                    if (bs_list.length == 0) {
                        let copy_data = JSON.parse(JSON.stringify(data_item))
                        bs_list.push(copy_data);
                    }
                    var grad_list = get_form_data.qualification_grad_list;
                    if (grad_list.length == 0) {
                        let copy_data = JSON.parse(JSON.stringify(data_item))
                        grad_list.push(copy_data);
                    }
                    var scholar_list = get_form_data.qualification_scholar_list;
                    if (scholar_list.length == 0) {
                        scholar_list.push(data_item);
                    }
                    break;
            }
        },
        //添加明细方法
        addChildItemFun(value) {
            let get_form_data = this.form;
            switch (value) {
                case 'qualification_high':
                    var data_item = JSON.parse(JSON.stringify(this.education_item))
                    get_form_data.qualification_high_list.push(data_item)
                    break;
                case 'qualification_college':
                    var data_item = JSON.parse(JSON.stringify(this.education_item))
                    get_form_data.qualification_college_list.push(data_item)
                    break;
                case 'qualification_bs':
                    var data_item = JSON.parse(JSON.stringify(this.education_item))
                    get_form_data.qualification_bs_list.push(data_item)
                    break;
                case 'qualification_grad':
                    var data_item = JSON.parse(JSON.stringify(this.education_item))
                    get_form_data.qualification_grad_list.push(data_item)
                    break;
                case 'qualification_scholar':
                    var data_item = JSON.parse(JSON.stringify(this.education_item))
                    get_form_data.qualification_scholar_list.push(data_item)
                    break;
                case 'vitae_list':
                    var data_item = JSON.parse(JSON.stringify(this.vitae_item))
                    get_form_data.vitae_list.push(data_item)
                    break;
                case 'family_list':
                    var data_item = JSON.parse(JSON.stringify(this.family_item))
                    get_form_data.family_list.push(data_item)
                    break;
            }
        },

        //移除明细方法
        delChildItemFun(parms) {
            let { index, filed_str } = parms
            if (!!!index || !!!filed_str) return false;
            let get_form_data = this.form;
            get_form_data[filed_str].splice(--index, 1)
        },

        //移出照片
        removeUploadFun(index, filed_str) {
            let getForm = this.form;
            getForm[filed_str].splice(index, 1)
        },
        //自定义上传的方法
        sendUpload(file, filed_str) {
            let formData = new FormData();
            formData.append('file', file);
            let filed_img_list = ['certificate_img_list', 'other_img_list'];
            uploadImageFile(formData).then(res => {
                let { code, url, newFileName, msg } = res;
                if (code == 200 && !!url) {
                    if (filed_img_list.indexOf(filed_str) != -1) {
                        this.$refs[filed_str].uploadSuccess([])
                        this.form[filed_str].push(url)
                    } else {
                        let show_pic = {
                            name: newFileName,
                            url: url
                        }
                        this.$refs[filed_str].uploadSuccess([show_pic])
                        this.form[filed_str] = url || ''
                    }
                } else {
                    this.$refs[filed_str].uploadSuccess([])
                    this.$message.warning('图片上传失败，请重试;', msg)
                }
            }).catch(err => {
                this.$refs[filed_str].uploadSuccess([])
                this.$message.warning('图片上传失败，请重试;', err)
            })
        },
        //
        baseFormCheck() {
            let check_1 = new Promise((resolve, reject) => {
                this.$refs.base_form.validate((valid) => {
                    if (valid) {
                        resolve(true)
                    } else {
                        reject('base_form')
                    }
                });
            })
            let check_2 = new Promise((resolve, reject) => {
                this.$refs.other_form.validate((valid) => {
                    if (valid) {
                        resolve(true)
                    } else {
                        reject('other_form')
                    }
                });
            })
            return new Promise((resolve, reject) => {
                let result_obj = {
                    mark: 'base_info_result'
                }
                Promise.all([check_1, check_2]).then(res => {
                    let copy_form = JSON.parse(JSON.stringify(this.form))
                    copy_form.native_place = copy_form.native_place.join('-')
                    copy_form.college_place = copy_form.college_place.join('-')
                    copy_form.region = copy_form.region.join('-')
                    result_obj.data = copy_form;
                    resolve(result_obj)
                }).catch(err => {
                    result_obj.msg = err
                    reject(result_obj)

                })
            })
        },
        cardFormCheck(check_list = []) {
            let result_obj = {}
            if (Array.isArray(check_list) && check_list.length > 0) {
                let promise_list = []
                for (let i = 0; i < check_list.length; i++) {
                    let check_item = check_list[i].submitCheck();
                    promise_list.push(check_item)
                }
                return new Promise((resolve, reject) => {
                    Promise.all(promise_list).then(res => {
                        result_obj.data = res
                        resolve(result_obj)
                    }).catch(err => {
                        result_obj.msg = err
                        reject(result_obj)
                    })
                })
            } else {
                return new Promise((resolve, reject) => {
                    result_obj.data = []
                    resolve(result_obj)
                })
            }

        },
        //提交前的检查
        async submitBefore() {
            let merge_Data = {}
            let check_base_result = false;
            await this.baseFormCheck().then(res => {
                let { data = {} } = res;
                merge_Data = { ...merge_Data, ...data }
                check_base_result = true
            }).catch(err => {
                check_base_result = false;
            })

            let qualification_high_refs = this.$refs.qualification_high_list;
            let check_qualification_high_result = false;
            await this.cardFormCheck(qualification_high_refs).then(res => {
                let { data = [] } = res;
                merge_Data.qualification_high_list = [...data]
                check_qualification_high_result = true
            }).catch(err => {
                check_qualification_high_result = false
            })

            let qualification_college_refs = this.$refs.qualification_college_list;
            let check_qualification_college_result = false;
            await this.cardFormCheck(qualification_college_refs).then(res => {
                let { data = [] } = res;
                merge_Data.qualification_college_list = [...data]
                check_qualification_college_result = true
            }).catch(err => {
                check_qualification_college_result = false
            })

            let qualification_bs_refs = this.$refs.qualification_bs_list;
            let check_qualification_bs_result = false;
            await this.cardFormCheck(qualification_bs_refs).then(res => {
                let { data = [] } = res;
                merge_Data.qualification_bs_list = [...data]
                check_qualification_bs_result = true
            }).catch(err => {
                check_qualification_bs_result = false
            })

            let qualification_grad_refs = this.$refs.qualification_grad_list;
            let check_qualification_grad_result = false;
            await this.cardFormCheck(qualification_grad_refs).then(res => {
                let { data = [] } = res;
                merge_Data.qualification_grad_list = [...data]
                check_qualification_grad_result = true
            }).catch(err => {
                check_qualification_grad_result = false
            })

            let qualification_scholar_refs = this.$refs.qualification_scholar_list;
            let check_qualification_scholar_result = false;
            await this.cardFormCheck(qualification_scholar_refs).then(res => {
                let { data = [] } = res;
                merge_Data.qualification_scholar_list = [...data]
                check_qualification_scholar_result = true
            }).catch(err => {
                check_qualification_scholar_result = false
            })

            let vitae_list_refs = this.$refs.vitae_list;
            let check_vitae_list_result = false;
            await this.cardFormCheck(vitae_list_refs).then(res => {
                let { data = [] } = res;
                merge_Data.vitae_list = [...data]
                check_vitae_list_result = true
            }).catch(err => {
                check_vitae_list_result = false
            })

            let family_list_refs = this.$refs.family_list;
            let check_family_list_result = false;
            await this.cardFormCheck(family_list_refs).then(res => {
                let { data = [] } = res;
                merge_Data.family_list = [...data]
                check_family_list_result = true
            }).catch(err => {
                check_family_list_result = false
            })


            let result_list = [check_base_result, check_qualification_high_result, check_qualification_college_result, check_qualification_bs_result, check_qualification_grad_result, check_qualification_scholar_result, check_vitae_list_result, check_family_list_result]
            let result_idx = result_list.findIndex(item => !!!item)

            switch (result_idx) {
                case 0:
                    this.$refs.base_area.scrollTop = 0;
                    break;
                case 1:
                    this.$refs.child_high_area.scrollTop = 0;
                    break;
                case 2:
                    this.$refs.child_college_area.scrollTop = 0;
                    break;
                case 3:
                    this.$refs.child_bs_area.scrollTop = 0;
                    break;
                case 4:
                    this.$refs.child_grad_area.scrollTop = 0;
                    break;
                case 5:
                    this.$refs.child_scholar_area.scrollTop = 0;
                    break;
                case 6:
                    this.$refs.vitae_area.scrollTop = 0;
                    break;
                case 7:
                    this.$refs.family_area.scrollTop = 0;
                    break;
            }
            let merge_result = check_base_result && check_qualification_high_result && check_qualification_college_result && check_qualification_bs_result && check_qualification_grad_result && check_qualification_scholar_result && check_vitae_list_result && check_family_list_result;
            let result_info = {
                result: merge_result,
                data: merge_Data
            }
            return result_info;
        },
        //提交数据
        async submitFun() {
            let chechResultInfo = await this.submitBefore()
            let { result, data } = chechResultInfo
            if (!!!result) {
                this.$message.error('您还有未填信息')
                return false;
            }
            let aggrement_notice = this.aggrement_notice
            if (!!!aggrement_notice) {
                this.$message.warning('请勾选信息真实性承诺')
                return false;
            } else {
                this.submitSignUp(data)
            }

        },
        //提交报名   levelRegistration
        submitSignUp(form_data) {
            let get_query = this.$route.query;
            form_data.talentIntroductionInfoId = this.job_detail.id || ''

            levelRegistration(form_data).then(res => {
                console.log('提交成功》》》', res)
                let {code, msg} = res;
                if(code == 200) {
                    this.$message.success('提交成功')
                    this.dialogVisible = false;
                }else {
                    this.$message.error(`提交失败 ${msg}`)
                }

            }).catch(err => {
                this.$message.error(`提交失败，请重试`)
            })
        },

        //弹框关闭的回调处理
        afterCloseInitFun() {
            let init_form = {
                talentIntroductionInfoId: '',
                name: '',
                phone: '',//手机
                near_photo: '',//近期照片
                IdNum: '',//身份证号码
                ID_img_front: '', //身份证照片--正面
                ID_img_back: '', //身份证照片--反面
                sex: '',//性别
                nation: '', //民族
                birthday: '', //出生日期
                native_place: '',//籍贯
                college_place: '',//生源地
                politice_status: '',//政治面貌
                educational_level: '',//学历
                region: '', //省市区
                address: '',//详细联系地址
                qualification_high_list: [], //高中学历
                qualification_college_list: [], //专科学历
                qualification_bs_list: [], //本科学历
                qualification_grad_list: [], //研究省学历
                qualification_scholar_list: [], //博士学历
                promise: '', //声明
                vitae_list: [{
                    start_date: '', //开始时间
                    end_date: '',//结束时间
                    organization_name: '',//单位名称
                    job_name: '',//职务
                },], //学习、工作经历
                family_list: [{
                    appellation: '',//称谓
                    name: '',//姓名
                    birthday: '',//出生年月
                    work_place: '',//工作单位或学校
                    job_name: '', //职务
                },], //家庭成员
                hobby: '',//爱好
                thesis_report: '',//论文、课题、社会实践经历
                certificate_img_list: [], //获奖证书照片
                other_img_list: [], //其他材料照片
                self_evaluation: '',//自我评价
                apply_date: '', //登记日期
            }
            let copy_form = JSON.parse(JSON.stringify(init_form))
            this.form = copy_form;
            this.job_detail = {}
            this.aggrement_notice = false;
            this.$refs.base_form.resetFields();
            this.$refs.ID_img_front.initFileList()
            this.$refs.ID_img_back.initFileList()
            this.$refs.near_photo.initFileList()

            this.dialogVisible = false;


        },

    }
}

</script>
<style lang="scss" scoped>
.main-cont,
.main-cont * {
    box-sizing: border-box !important;
}

.main-cont {
    width: auto;
    height: auto;
    overflow: hidden;
    // background: red;

    .model-box {
        width: auto;
        height: auto;
        overflow: hidden;
        margin-bottom: 20px;
        border-radius: 10px;
        border: 1px solid #d8d8d8;

        .model-title {
            // padding-left:10px;
            font-size: 17px;
            color: #000;
            padding: 10px;
            border-bottom: 1px dashed #ccc;
            display: flex;
            justify-content: space-between;

            .model-title-box {
                display: inline-flex;
            }

            .model-title-btn {
                display: inline-flex;
                // .model-toggle-btn {
                //     width:20px;
                //     height:20px;
                //     display: inline-flex;
                //     justify-content: center;
                //     align-items: center;
                //     border-radius: 50%;
                //     border:1px solid #ccc;
                // }
            }
        }

        .model-box-cont {
            width: auto;
            height: auto;
            overflow: hidden;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            padding: 10px;

            .model-box-cont-item-out {
                position: relative;
                top: -12px;
            }

            .model-box-cont-item {
                width: calc(100% / 4);
                padding-right: 10px;
                display: inline-block;

                .model-box-cont-item {
                    padding-right: 0;
                }

                // display: flex;
                // align-items: center;
                .img-box {
                    height: 100px;
                }

                .img-list {
                    display: flex;
                    flex-wrap: wrap;

                    .img-box {
                        width: 145px;
                        margin: 0 10px 10px 0;
                        border: 1px dashed #d9d9d9;
                        border-radius: 6px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        position: relative;

                        .img-box-btn {
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 500;
                            background: rgba(0, 0, 0, .5);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 2px;
                            cursor: pointer;

                            i {
                                color: #fff;
                            }

                        }
                    }

                    .img-box:last-child {
                        border: none;
                    }
                }

            }

            .model-box-cont-item-2 {
                width: calc((100% / 4) * 2);
            }

            .model-box-cont-item-3 {
                width: calc((100% / 4) * 3);
            }

            .model-box-cont-item-4 {
                width: calc((100% / 4) * 4);
            }

            .model-box-cont-derail {
                display: inline-flex;
                margin: 10px auto;

                .model-box-cont-item-label {
                    width: 85px;
                    display: inline-block;
                    text-align: right;
                    padding-right: 11px;
                    line-height: 1.2;
                    vertical-align: text-top;
                    padding-top: 2px;

                }

                .model-box-cont-item-value {
                    width: calc(100% - 85px);
                    display: inline-block;
                    color: #000;
                    // border: 1px solid #ccc;

                }
            }
        }


    }

    .model-box-inner {
        .model-title {
            padding: 5px 0;
        }
    }

    .notice-box {
        padding: 0 20px;
        font-size: 14px;
        color: #6c6c6c;

        span {
            color: #0077ff;
        }
    }

}

.agree-box {
    margin: 20px auto;
    width: 100%;

    .agree-box-txt {
        // margin-left: 10px;
        // position: relative;
        // inset: -4px 0;
        display: inline-block;
        word-break: break-all;
        // color:#cc0000;
    }
}

.dialog-footer {
    display: flex;
    justify-content: center;
}

:deep(.el-dialog__body) {
    padding: 20px !important;
}


:deep(.el-form-item__label) {
    margin-top: 10px;
    line-height: 1.2 !important;
}

:deep(.el-date-editor) {
    width: 100% !important;
}

:deep(.el-button--mini) {
    padding: 4px 15px !important;
}

:deep(.is-circle.el-button--mini) {
    padding: 5px !important;
}

:deep(.el-input__inner) {
    // padding: 0 !important;
}

:deep(.el-input__prefix, .el-input__suffix) {
    // display: none !important;
}

:deep(.el-checkbox) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: auto;
    overflow: hidden;
    border-radius: 10px;

    .el-checkbox__label {
        width: calc(100% - 20px);
        color: #a50000;

        .agree-box-txt {
            width: 100%;
            word-break: break-all;
            white-space: normal;
        }
    }
}
</style>