<template>
    <div>
        <!-- 基本信息 -->
        <div class="model-box">
            <div class="model-box-top">
                <div class="model-box-index">{{ index }}</div>
                <div class="model-box-close" v-if="index > 1" @click="removeHandle">
                    <i class="el-icon-circle-close"></i>
                </div>
            </div>
            <el-form ref="form" :model="form" :rules="rules" label-width="85px">
                <div class="model-box-cont">

                    <div class="model-box-cont-item">
                        <el-form-item label="称谓:" prop="appellation">
                            <el-input v-model="form.appellation" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="姓名:" prop="name">
                            <el-input v-model="form.name" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="出生日期:" prop="birthday">
                            <el-date-picker v-model="form.birthday"  value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" size="small" />
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="工作单位/学校:" prop="work_place">
                            <el-input v-model="form.work_place" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="职务:" prop="job_name">
                            <el-input v-model="form.job_name" size="small"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>

    </div>
</template>
<script>
import uploadImg from './uploag_img.vue'
export default {
    components: {
        uploadImg
    },
    props: {
        index: { type: [String, Number], default: '1' },
        filed: { type: String, default: '' },
        form: {
            type: Object, default: () => ({
                appellation: '',//称谓
                name: '',//姓名
                birthday: '',//出生年月
                work_place: '',//工作单位或学校
                job_name: '', //职务
            })
        }
    },
    data() {
        return {
            rules: {}

        }
    },
    mounted() {

    },
    beforeDestroy(){
        this.$refs.form.resetFields();
    },
    methods: {

        submitCheck() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let copy_form = JSON.parse(JSON.stringify(this.form))
                        resolve(copy_form)
                    } else {
                        reject('还有未填写项')
                    }
                });

            })
        },

        //删除自身节点
        removeHandle(){
            this.$emit('removeCall', {index: this.index, filed_str: this.filed})
        },

    }
}
</script>
<style lang="scss" scoped>
.model-box,
.model-box * {
    box-sizing: border-box;
}

.model-box {
    width: auto;
    height: auto;
    overflow: hidden;
    margin: 0 10px 20px 0;
    border-radius:5px;
    border: 1px dashed #e4e4e4;

    .model-box-top {
        padding: 10px 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .model-box-index {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #e4e4e4;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #000;
        }

        .model-box-close {
            font-size: 20px;

            i {
                color: #c80000;
                cursor: pointer;
            }

        }
    }

    .model-box-cont {
        width: auto;
        height: auto;
        overflow: hidden;

        padding: 10px 10px 0px;

        .model-box-cont-item {
            width: calc(100% / 4);
            display: inline-block;
            padding-right: 10px;

            // display: flex;
            // align-items: center;
            .img-box {
                height: 100px;
            }

            .img-list {
                display: flex;
                flex-wrap: wrap;

                .img-box {
                    width: 145px;
                    margin: 0 10px 10px 0;
                }
            }
        }

        .model-box-cont-item-2 {
            width: calc((100% / 4) * 2);
        }

        .model-box-cont-item-3 {
            width: calc((100% / 4) * 3);
        }

        .model-box-cont-item-4 {
            width: calc((100% / 4) * 4);
        }
    }


}
</style>
