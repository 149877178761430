<template>
    <div>
        <!-- 基本信息 -->
        <div class="model-box">
            <div class="model-box-top">
                <div class="model-box-index">{{ index }}</div>
                <div class="model-box-close" v-if="index > 1" @click="removeHandle">
                    <i class="el-icon-circle-close"></i>
                </div>
            </div>
            <el-form ref="form" :model="form" :rules="rules" label-width="85px">
                <div class="model-box-cont">

                    <div class="model-box-cont-item">
                        <el-form-item label="毕业院校:" prop="school_name">
                            <el-input v-model="form.school_name" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="专业:" prop="specialty">
                            <el-input v-model="form.specialty" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="主修课程:" prop="main_course">
                            <el-input v-model="form.main_course" size="small"></el-input>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item :label=" degree_cert ? '获得学位时间:' : '毕业时间:'" prop="graduation_time">
                            <el-date-picker v-model="form.graduation_time"  value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" size="small" />
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item" v-if="degree_cert">
                        <el-form-item label="学位证书:" prop="degree_img">
                            <div class="img-box">
                                <upload-img ref="degree_img" filed="degree_img" isSingle
                                    :callFun="sendUpload"></upload-img>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item">
                        <el-form-item label="毕业证书:" prop="graduation_img">
                            <div class="img-box">
                                <upload-img ref="graduation_img" filed="graduation_img" isSingle
                                    :callFun="sendUpload"></upload-img>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="model-box-cont-item" v-if="chsi_cert">
                        <el-form-item label="学信网信息页截图" prop="chsi_img">
                            <div class="img-box">
                                <upload-img ref="chsi_img" filed="chsi_img" isSingle :callFun="sendUpload"></upload-img>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>

    </div>
</template>
<script>
import { uploadImageFile } from "@/api/index.js";
import uploadImg from './uploag_img.vue'
export default {
    components: {
        uploadImg
    },
    props: {
        index: { type: [String, Number], default: '1' },
        filed: { type: String, default: '' },
        degree_cert: {type: Boolean, default: true},//是否上传学位证书
        chsi_cert:{type: Boolean, default: true},//是否上传学信网截图
        form: {
            type: Object, default: () => ({
                school_name: '',//毕业学校
                specialty: '',//专业
                main_course: '', //主修课程
                graduation_time: '',//获得学位时间
                graduation_img: '',//毕业证照片
                degree_img: '',//学位证照片
                chsi_img: '', //学信网信息截图
            })
        }
    },
    data() {
        return {
            rules: {
                school_name: [
                    { required: true, message: '请输入学校名称' },
                ],
                specialty: [
                    { required: true, message: '请输入所学专业' },
                ],
                main_course: [
                    { required: true, message: '请输入主修课程' },
                ],
                graduation_time: [
                    { required: true, message: '请选择时间' },
                ],
                graduation_img: [
                    { required: true, message: '请上传毕业证书',trigger: 'change' },
                ],
                degree_img: [
                    { required: true, message: '请上传学位证书',trigger: 'change' },
                ],
                chsi_img: [
                    { required: true, message: '请上传学信网信息截图',trigger: 'change' },
                ],
            }

        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.$refs.form.resetFields();
    },
    methods: {

        submitCheck() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let copy_form = JSON.parse(JSON.stringify(this.form))
                        resolve(copy_form)
                    } else {
                        reject('还有未填写项')
                    }
                });

            })
        },

        //删除自身节点
        removeHandle() {
            this.$emit('removeCall', { index: this.index, filed_str: this.filed })
        },

        /////////////////////////////////////////////////////////////////
        //自定义上传的方法
        sendUpload(file, filed_str) {
            let formData = new FormData();
            formData.append('file', file);
            uploadImageFile(formData).then(res => {
                let { code, url, newFileName, msg } = res;
                if (code == 200 && !!url) {
                    //选取压缩的图片作为上传组件展示预览的信息
                    let show_pic = {
                        name: newFileName,
                        url: url
                    }
                    this.$refs[filed_str].uploadSuccess([show_pic])
                    this.form[filed_str] = url || ''

                } else {
                    this.$refs[filed_str].uploadSuccess([])
                    this.$message.warning('图片上传失败，请重试;', msg)
                }
            }).catch(err => {
                this.$refs[filed_str].uploadSuccess([])
                this.$message.warning('图片上传失败，请重试;', err)
            })

        },

    }
}
</script>
<style lang="scss" scoped>
.model-box,
.model-box * {
    box-sizing: border-box;
}

.model-box {
    width: auto;
    height: auto;
    overflow: hidden;
    margin: 0 10px 20px 0;
    border-radius: 5px;
    border: 1px dashed #e4e4e4;

    .model-box-top {
        padding: 10px 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .model-box-index {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #e4e4e4;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #000;
        }

        .model-box-close {
            font-size: 20px;

            i {
                color: #c80000;
                cursor: pointer;
            }

        }
    }

    .model-box-cont {
        width: auto;
        height: auto;
        overflow: hidden;

        padding: 10px 10px 0px;

        .model-box-cont-item {
            width: calc(100% / 4);
            display: inline-block;
            padding-right: 10px;

            // display: flex;
            // align-items: center;
            .img-box {
                height: 100px;
            }

            .img-list {
                display: flex;
                flex-wrap: wrap;

                .img-box {
                    width: 145px;
                    margin: 0 10px 10px 0;
                }
            }
        }

        .model-box-cont-item-2 {
            width: calc((100% / 4) * 2);
        }

        .model-box-cont-item-3 {
            width: calc((100% / 4) * 3);
        }

        .model-box-cont-item-4 {
            width: calc((100% / 4) * 4);
        }
    }


}
</style>
