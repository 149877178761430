<template>
    <div class="laycont">
        <div class="laycont-box" v-loading="upload_process">
            <el-upload :action="uploadUrl" list-type="picture-card" :before-upload="beforeUpload" :disabled="disabled"
                :show-file-list="false" class="avatar-uploader" :http-request="handleUploadFun"
                :on-preview="handlePreview" v-if="isSingle">
                <el-image :src="imageUrl" fit="contain" v-if="imageUrl" />
                <div class="icon-box" v-else>
                    <i class="el-icon-upload2"></i>
                </div>
            </el-upload>
            <template v-else>
                <el-upload :action="uploadUrl" list-type="picture-card" :before-remove="handleRemove"
                    :before-upload="beforeUpload" :disabled="disabled" :file-list="fileList"
                    :http-request="handleUploadFun" :on-preview="handlePreview" class="img-uploader">
                    <div class="icon-box">
                        <i class="el-icon-plus"></i>
                    </div>
                </el-upload>
                <el-dialog v-model="dialogVisible" append-to-body>
                    <img :src="imageUrl" alt="" width="100%">
                </el-dialog>
            </template>
        </div>

    </div>
</template>

<script>
export default {
    name: 'upload-img',
    props: {
        value: {
            type: [String, Array],
            default() { }
        },
        isSingle: { // 是否是单张图片
            type: Boolean,
            default: true
        },
        uploadUrl: {
            type: String,
            default: ``
        },
        //上传文件大小限制
        fileSize: {
            type: Number,
            default: parseInt(1024 * 1024 * 20) / 1024 / 1024, //单位：M
        },
        //diy上传方法回调
        callFun: {
            type: Function,
            default: () => { }
        },
        //刷新方法回调
        refushVal: {
            type: Function,
            default: () => { }
        },
        //是否禁用上传，禁止点击
        disabled: {
            type: Boolean,
            default: false
        },
        filed: {
            type: String,
            default: ''
        }
    },
    emit: ['input'],
    data() {
        return {
            imageUrl: '',
            fileList: [],
            dialogVisible: false, //图片预览展开、隐藏控制
            upload_process: false,
        };
    },

    watch: {
        fileList: {
            handler(newArr, oldArr) {
                //更新预览图片的信息
                let newLen = newArr.length
                newLen = newLen > 0 ? --newLen : newLen;
                let lastImg = newArr[newLen] || ''
                this.imageUrl = !!!lastImg ? '' : lastImg.url || '';
                //调用刷新父级数据
                let arr = []
                newArr.forEach(item => {
                    arr.push({
                        name: item.name,
                        url: item.url
                    })
                })
                this.refushVal(arr)
            },
            immediate: true,
            deep: true
        },
        // value(val) {
        //     const {
        //         isSingle
        //     } = this;
        //     if (isSingle) {
        //         this.imageUrl = val;
        //     } else {
        //         this.imageUrl = val || [];
        //         this.fileList = val || []
        //     }
        // }
    },
    beforeDestory() {
        this.initFileList([])
    },

    methods: {
        beforeUpload(file) {
            return this.checkFile(file)
        },
        checkFile(file) {
            const {
                fileSize
            } = this;
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
            const isLt2M = file.size / 1024 / 1024 < fileSize;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error(`上传图片大小不能超过 ${fileSize}MB!`);
            }
            return isJPG && isLt2M;

        },
        //删除文件，回调父级函数更新处理
        handleRemove(file, fileList) {
            this.$emit('input', fileList);
        },
        //自动上传模式，自定义上传方法
        handleUploadFun(params) {
            const file = params.file;
            //检查图片 &&（并回调上传fun）
            this.checkFile(file) && this.sendUpload(file)
        },
        //发送上传文件
        sendUpload(files) {
            // var form = new FormData();
            // files.forEach(item => {
            //     // form.append("file", item, item.name);
            //     form.append("file", item);
            // })
            this.upload_process = true;
            this.callFun(files,this.filed)

        },


        handlePreview(file) {
            this.imageUrl = file.url;
            this.dialogVisible = true;
        },

        //上传成功，刷新文件列表（父组件的上传方法成功后的回调，刷新本组件的文件列表）
        //data: array 上传成功之后返回的文件列表
        //formData： formdata  上传文件提交的form表单对象，获取上传文件时候的文件名字
        uploadSuccess(data, formData) {
            this.upload_process = false;
            if (data.length == 0) {
                this.fileList = [...this.fileList]
            } else {
                this.refushFileList(data)
            }
        },

        //刷新文件列表方法
        refushFileList(files) {
            files.forEach((item) => {
                // let getName = item.imageUrl.match(/\/(\w+\.(?:png|jpg|gif|bmp))$/i)[1];
                // this.fileList.push({
                //     name: getName,
                //     url: item.imageUrl
                // })


                // let getName = item.url.match(/\/(\w+\.(?:png|jpg|gif|bmp))$/i)[1];
                this.fileList.push({
                    name: item.name || '',
                    url: item.url
                })
            })
        },
        //重置，初始化，清空文件列表
        initFileList(files) {
            this.fileList = files || []
        },

    }
}
</script>

<style lang="scss" scoped>
.laycont,
.laycont * {
    box-sizing: border-box !important;
}

.laycont {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .laycont-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

}

.img-uploader {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }
}

.icon-box {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.el-upload--picture-card) {
    font-size: 28px;
    color: #8c939d;
    // width: 150px;
    // height: 100px;
    width: 100%;
    height: 100%;
    // line-height: 100px;
    text-align: center;

    img {
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
        display: block;
    }
}

:deep(.el-upload-list--picture-card .el-upload-list__item) {
    width: 100px;
    height: 100px;
}

:deep(.el-upload-list--picture-card .el-upload-list__item)-thumbnail {
    height: auto;
}

:deep(.avatar-uploader) {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

:deep(.avatar-uploader .el-upload) {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

}

:deep(.avatar-uploader .el-upload:hover) {
    border-color: #409EFF;
}



:deep(.avatar) {
    width: 100%;
    height: 100%;
    display: block;
}
</style>